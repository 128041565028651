import React from "react";
import CommonLayout from "../layout/CommonLayout";
import BackClick from "../components/BackClick";
import SingleCartProduct from "../components/cartelements/SingleCartProduct";
import cart1 from "../assets/images/cart1.png";
import cart2 from "../assets/images/cart2.png";
import coupon from "../assets/images/icons/applyCouponIcon.svg";
import arrow from "../assets/images/icons/checkCouponArrow.svg";
import deleteCoupon from "../assets/images/icons/deleteCouponIcon.svg";
import tick from "../assets/images/icons/COUPONTICK.svg";
import { useDispatch, useSelector } from "react-redux";
import { getCoupon } from "../reducer/couponSlice";

function Cart() {
  const currentCoupon = useSelector((state) => state.couponControl?.coupon);
  const dispatch = useDispatch();
  return (
    <CommonLayout>
      <BackClick />
      <div
        style={{
          backgroundColor: "#fff",
          marginTop: "5px",
          padding: "10px",
          minHeight: "92vh",
          height: "fit-content",
          paddingBottom: "100px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <SingleCartProduct
            src={cart1}
            variantname={"variant"}
            value={"440"}
            productName={"Imaaya Ajrakh Sari"}
            price={"7,500"}
          />
          <SingleCartProduct
            src={cart2}
            variantname={"variant"}
            value={"440"}
            productName={"Imaaya Ajrakh Sari"}
            price={"1,500"}
          />
        </div>
        <hr />

        <h5>Other Charges</h5>
        <hr />
        <div>
          <div className="d-flex justify-content-between">
            <p style={{ marginBottom: "0px", fontSize: "14px", color: "#333" }}>
              Shipping Charges
            </p>
            <p style={{ marginBottom: "0px", fontSize: "14px", color: "#333" }}>
              Rs. 120
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p style={{ marginBottom: "0px", fontSize: "14px", color: "#333" }}>
              Payment Gateway Charges
            </p>
            <p style={{ marginBottom: "0px", fontSize: "14px", color: "#333" }}>
              Rs. 50
            </p>
          </div>
          {currentCoupon !== "" && (
            <div className="d-flex justify-content-between">
              <p
                style={{ marginBottom: "0px", fontSize: "14px", color: "#333" }}
              >
                Coupon discount
              </p>
              <p
                style={{
                  marginBottom: "0px",
                  fontSize: "14px",
                  color: "#1DD75B",
                }}
              >
                - Rs. 900
              </p>
            </div>
          )}
        </div>
        <hr />
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            backgroundColor: "#F0F1F6",
            position: "relative",
          }}
          data-bs-toggle={currentCoupon === "" ? "modal" : ""}
          data-bs-target={currentCoupon === "" ? "#applycoupon" : ""}
        >
          <img src={coupon} alt="" />
          <span
            style={{
              fontWeight: "500",
              fontSize: "14px",
              color: "#3371A5",
              marginLeft: "16px",
            }}
          >
            {currentCoupon === "" ? "Apply discount coupon" : currentCoupon}
          </span>
          {currentCoupon === "" ? (
            <img
              style={{ position: "absolute", top: "33%", right: "8px" }}
              src={arrow}
              alt=""
            />
          ) : (
            <img
              style={{ position: "absolute", top: "23%", right: "8px" }}
              src={deleteCoupon}
              alt=""
              onClick={() => dispatch(getCoupon(""))}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontSize: "16px",
              color: "#333",
              marginBottom: "0px",
              fontWeight: "600",
            }}
          >
            Total Bill (incl. taxes, charges):
          </p>
          <p
            style={{
              marginBottom: "0px",
              fontSize: "16px",
              fontWeight: "500",
              color: "#3371A5",
            }}
          >
            Rs. 9,120
          </p>
        </div>
        {currentCoupon !== "" && (
          <div
            style={{ marginTop: "20px", fontSize: "14px", color: "#1DD75B" }}
          >
            <img src={tick} alt="" />
            <span style={{ marginLeft: "10px" }}>
              You saved Rs. 4,500 with this order{" "}
            </span>
          </div>
        )}
      </div>
      <button
        className="btn "
        style={{
          display: "flex",
          width: "90%",
          padding: "10px",
          borderRadius: "10px",
          backgroundColor: "#3371A5",
          color: "#fff",
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          bottom: "14px",
          left: "0",
          right: "0",
          marginLeft: "auto",
          marginRight: "auto",
          gap: "14px",
        }}
        data-bs-toggle="modal"
        data-bs-target="#proceedtobuy"
        // onClick={() => navigate(`/${userId}/cart`)}
      >
        <p style={{ marginBottom: "0px" }}>Proceed to Buy</p>
      </button>
    </CommonLayout>
  );
}

export default Cart;
