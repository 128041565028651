import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

function ImgOrVideo({ url, uid, pid, type }) {
  const [onError, setOnError] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });
  const navigate = useNavigate();
  const handleProductClick = (productId) => {
    navigate(`/productdetails/${uid}/${productId}`);
  };
  return (
    <>
      {type === 1 ? (
        onError === false ? (
          <img
            className="product-img"
            src={url}
            alt=""
            onError={() => setOnError(true)}
          />
        ) : (
          <div ref={ref}>
            <ReactPlayer
              url={url}
              playing={inView}
              controls={true}
              muted={false}
              loop
              width={"100%"}
              height={"482px"}
              className="product-img"
            />
          </div>
        )
      ) : onError === false ? (
        <img
          onClick={() => handleProductClick(pid)}
          onError={() => setOnError(true)}
          src={url}
          alt=""
        />
      ) : (
        <video
          onClick={() => handleProductClick(pid)}
          width={"100%"}
          height={"100%"}
          autoPlay
          loop
          muted
        >
          <source src={url} type="video/mp4" />
        </video>
      )}
    </>
  );
}

export default ImgOrVideo;
