import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/file";
import { useNavigate, useParams } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import VariantList from "../components/VariantList";
import ProductsMapList from "../components/ProductsMapList";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "../layout/CommonLayout";
import { getModalState } from "../reducer/manageModalStateSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { getList } from "../reducer/productListSlice";
import ShareButton from "../components/modal/WebShareBtn";
import { getSizeChart } from "../reducer/commonValuesSlice";
import { isVideo } from "../utils/utils";
import { setTab } from "../reducer/controlTab";
import { useInView } from "react-intersection-observer";
import BackClick from "../components/BackClick";
import ImgOrVideo from "../components/ImgOrVideo";

function ProductDetails() {
  const dispatch = useDispatch();
  const { productId, userId } = useParams();
  const uid = useSelector((state) => state.fetchUser?.user?.id);
  // const { ref, inView } = useInView({
  //   triggerOnce: false,
  //   threshold: 0.5,
  // });

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userid", userId);
    }
  }, [userId]);
  const pId = useSelector((state) => state.productslst?.particularId);
  const staticUrl = `${baseUrl}productdetails/${userId && userId}/${
    pId ? pId : productId
  }`;
  const productList = useSelector((store) => store.productslst?.products);
  const userName = useSelector((state) => state.fetchUser?.user?.name);
  const filterVariant = useSelector(
    (store) => store.filterVariantLst?.filterVariant
  );
  const [constItemDetails, setConstItemDetails] = useState({
    title: "",
    des: "",
    img: [],
  });
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [productAttributes, setProductAttributes] = useState();
  const [productVariant, setProductVariant] = useState();
  const [firstMatchingVariant, setFirstMatchingVariant] = useState();
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (window.history.length > 1) {
      navigate(-1);
      dispatch(setTab(2));
    } else {
      navigate(`/${userId}`);
      dispatch(setTab(2));
    }
  };

  // useEffect(() => {
  //   clearCacheData();
  // }, []);
  // const shareBtnRef = useRef(null);

  // useEffect(() => {
  //   const sharebtn = shareBtnRef.current; // Access the button via the ref

  //   const handleClick = async (event) => {
  //     if (navigator.share) {
  //       await navigator
  //         .share({
  //           title: "test",
  //           url: "https://your-static-url.com", // Use your actual static URL here
  //         })
  //         .then(() => {
  //           console.log("shared");
  //         })
  //         .catch((err) => {
  //           console.log("error using web share api");
  //           console.log(err);
  //         });
  //     } else {
  //       alert("browser doesn't support web share api");
  //     }
  //     // console.log("navi",navigator)
  //   };

  //   // Add the event listener
  //   sharebtn.addEventListener("click", handleClick);

  //   // Clean up the event listener on unmount
  //   return () => {
  //     sharebtn.removeEventListener("click", handleClick);
  //   };
  // }, []);

  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiBaseUrl}user/get-seller-product-details/${
          uid && uid
        }/${productId}`
      );
      const data = await response.json();
      setLoading(false);
      setProductDetails(data?.product);
      dispatch(getSizeChart(data?.product?.sizeChart));
      setProductAttributes(data?.product?.additionalInfo?.attributes);
      setProductVariant(data?.product?.additionalInfo?.productVariants);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching Product Details:", error);
    }
  };

  const fetchProductList = async () => {
    try {
      const response = await fetch(
        `${apiBaseUrl}user/get-related-products/${productId}`
      );
      const data = await response.json();
      dispatch(getList(data?.products));
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };
  useEffect(() => {
    fetchProductList();
  }, []);
  const variantName = [
    ...new Set(
      ...new Set(
        productVariant?.map((variant) =>
          variant.variants.map((item) => item.title)
        )
      )
    ),
  ];

  // console.log("variantname", variantName);
  const firstVariantTitles = [
    ...new Set(
      productVariant?.map((variant) => variant.variants[0].value.title)
    ),
  ];
  const secondVariantTitles = [
    ...new Set(
      productVariant?.map((variant) => variant.variants[1].value.title)
    ),
  ];

  useEffect(() => {
    if (productId && uid !== undefined) {
      fetchProductDetails();
    }
  }, [productId, uid]);

  useEffect(() => {
    if (productDetails) {
      setConstItemDetails({
        title: productDetails?.title,
        des: productDetails?.description,
        img: productDetails?.images,
      });
    }
  }, [productDetails]);

  function findFirstVariant(title1, title2) {
    // Ensure productVariant is not null or undefined before proceeding
    if (!productVariant) {
      return null;
    }

    return (
      productVariant.find((variant) => {
        const title1Match = variant?.variants?.[0]?.value?.title === title1;
        const title2Match = variant?.variants?.[1]?.value?.title === title2;

        if (title1 && title2) {
          return title1Match && title2Match;
        } else if (title1) {
          return title1Match;
        } else if (title2) {
          return title2Match;
        } else {
          return null;
        }
      }) || null
    ); // Return null if no match is found
  }

  // Example usage
  useEffect(() => {
    if (filterVariant) {
      setFirstMatchingVariant(() =>
        findFirstVariant(
          filterVariant.firstVariant,
          filterVariant.secondVariant
        )
      );
    }
    // console.log("filtervariant", filterVariant);
    // console.log(firstMatchingVariant);
  }, [filterVariant]);

  return (
    <CommonLayout>
      <BackClick />
      <div className="d-flex justify-content-between  ProductItemContainer p-0">
        <div className="ProductItemDetails">
          <div className="ItemStatus">
            <span>SALE</span>
          </div>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {constItemDetails?.img?.map((item, index) => (
              <SwiperSlide key={index}>
                {/* {isVideo(item) ? (
                  <div ref={ref}>
                    <ReactPlayer
                      url={item} 
                      playing={inView}
                      controls={true}
                      muted={false}
                      loop
                      width={"100%"}
                      height={"482px"}
                      className="product-img"
                    />
                  </div>
                ) : (
                  <img className="product-img" src={item} alt="" />
                )} */}

                <ImgOrVideo url={item} type={1}/>
              </SwiperSlide>
            ))}
          </Swiper>
          {/* <img className="product-img" src={constItemDetails?.img[0]} alt="" /> */}
          <div className="p_viewAll">
            <img src="/Images/Icons/Viewall.png" alt="" />
            <ShareButton url={staticUrl && staticUrl}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                // data-bs-toggle="modal"
                // data-bs-target="#socialshare"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.625 3.5625C19.625 5.49609 18.0781 7 16.1875 7C15.1992 7 14.2969 6.61328 13.6523 5.92578L7.16406 9.19141C7.20703 9.36328 7.25 9.57812 7.25 9.75C7.25 9.96484 7.20703 10.1797 7.16406 10.3516L13.6523 13.5742C14.2969 12.9297 15.1992 12.5 16.1875 12.5C18.0781 12.5 19.625 14.0469 19.625 15.9375C19.625 17.8711 18.0781 19.375 16.1875 19.375C14.2539 19.375 12.75 17.8711 12.75 15.9375C12.75 15.5508 12.793 15.1211 12.9219 14.7773L6.69141 11.6406C6.04688 12.5859 5.01562 13.1875 3.8125 13.1875C1.87891 13.1875 0.375 11.6836 0.375 9.75C0.375 7.85938 1.87891 6.3125 3.8125 6.3125C5.01562 6.3125 6.04688 6.95703 6.69141 7.90234L12.9219 4.76562C12.793 4.37891 12.75 3.99219 12.75 3.5625C12.75 1.67188 14.2539 0.125 16.1875 0.125C18.0781 0.125 19.625 1.67188 19.625 3.5625ZM3.76953 11.8125C4.92969 11.8125 5.83203 10.9102 5.83203 9.75C5.83203 8.63281 4.92969 7.6875 3.76953 7.6875C2.65234 7.6875 1.70703 8.63281 1.70703 9.75C1.70703 10.9102 2.65234 11.8125 3.76953 11.8125ZM16.1875 1.5C15.0273 1.5 14.125 2.44531 14.125 3.5625C14.125 4.72266 15.0273 5.625 16.1875 5.625C17.3047 5.625 18.25 4.72266 18.25 3.5625C18.25 2.44531 17.3047 1.5 16.1875 1.5ZM16.1875 18C17.3047 18 18.25 17.0977 18.25 15.9375C18.25 14.8203 17.3047 13.875 16.1875 13.875C15.0273 13.875 14.125 14.8203 14.125 15.9375C14.125 17.0977 15.0273 18 16.1875 18Z"
                  fill="#FBFBFB"
                />
              </svg>
            </ShareButton>
            {/* <button
              // onChange={onShareClick}
              ref={shareBtnRef}
              id="shareBtn"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                // data-bs-toggle="modal"
                // data-bs-target="#socialshare"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.625 3.5625C19.625 5.49609 18.0781 7 16.1875 7C15.1992 7 14.2969 6.61328 13.6523 5.92578L7.16406 9.19141C7.20703 9.36328 7.25 9.57812 7.25 9.75C7.25 9.96484 7.20703 10.1797 7.16406 10.3516L13.6523 13.5742C14.2969 12.9297 15.1992 12.5 16.1875 12.5C18.0781 12.5 19.625 14.0469 19.625 15.9375C19.625 17.8711 18.0781 19.375 16.1875 19.375C14.2539 19.375 12.75 17.8711 12.75 15.9375C12.75 15.5508 12.793 15.1211 12.9219 14.7773L6.69141 11.6406C6.04688 12.5859 5.01562 13.1875 3.8125 13.1875C1.87891 13.1875 0.375 11.6836 0.375 9.75C0.375 7.85938 1.87891 6.3125 3.8125 6.3125C5.01562 6.3125 6.04688 6.95703 6.69141 7.90234L12.9219 4.76562C12.793 4.37891 12.75 3.99219 12.75 3.5625C12.75 1.67188 14.2539 0.125 16.1875 0.125C18.0781 0.125 19.625 1.67188 19.625 3.5625ZM3.76953 11.8125C4.92969 11.8125 5.83203 10.9102 5.83203 9.75C5.83203 8.63281 4.92969 7.6875 3.76953 7.6875C2.65234 7.6875 1.70703 8.63281 1.70703 9.75C1.70703 10.9102 2.65234 11.8125 3.76953 11.8125ZM16.1875 1.5C15.0273 1.5 14.125 2.44531 14.125 3.5625C14.125 4.72266 15.0273 5.625 16.1875 5.625C17.3047 5.625 18.25 4.72266 18.25 3.5625C18.25 2.44531 17.3047 1.5 16.1875 1.5ZM16.1875 18C17.3047 18 18.25 17.0977 18.25 15.9375C18.25 14.8203 17.3047 13.875 16.1875 13.875C15.0273 13.875 14.125 14.8203 14.125 15.9375C14.125 17.0977 15.0273 18 16.1875 18Z"
                  fill="#FBFBFB"
                />
              </svg>
            </button> */}
          </div>
        </div>
      </div>

      <div className="product-des">
        <div className="row">
          <div className="col-7 p-0">
            <h5>{constItemDetails?.title}</h5>
            <div className="price">
              <span>
                <del>
                  Rs.{" "}
                  {firstMatchingVariant?.mrp
                    ? firstMatchingVariant?.mrp
                    : productDetails?.mrp}
                </del>
              </span>
              <span className="main-price">
                Rs.{" "}
                {firstMatchingVariant?.price
                  ? firstMatchingVariant.price
                  : productDetails?.price}
              </span>
            </div>
            <p>Price inclusive of taxes</p>
          </div>
          <div className="col-5 d-flex align-items-center p-0">
            <button
              className="add_to_cart_brn"
              data-bs-toggle="modal"
              data-bs-target="#addCart"
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #677B8A50",
          margin: "7px",
          paddingBottom: "13px",
        }}
      >
        {variantName?.map((item, index) => (
          <VariantList
            key={item}
            indx={index}
            title={item}
            list={
              index === 0
                ? firstVariantTitles && firstVariantTitles
                : secondVariantTitles && secondVariantTitles
            }
            isLoading={loading}
          />
        ))}
      </div>
      <div className="size-chart">
        <p>Check Size</p>
        <button data-bs-toggle="modal" data-bs-target="#Sizechart">
          Size chart
        </button>
      </div>

      <div className="Variant-group-des">
        <p>{constItemDetails?.des}</p>
      </div>
      {productAttributes?.length > 0 && (
        <div className="product-additional-status">
          <div className="row">
            {productAttributes?.map((item, index) => (
              // <div className="col-6" key={index}>
              //   <div className="row ">
              //     <div className="col-12">
              //       <strong>{item.title}:</strong>
              //     </div>
              //     <div className="col-12 ps-2 p-0">
              //       <span>
              //         {item.values?.map((value, idx) => (
              //           <span key={idx}>
              //             {value.title}
              //             {idx < item.values.length - 1 && ", "}
              //           </span>
              //         ))}
              //       </span>
              //     </div>
              //   </div>
              // </div>

              <div className="col-6">
                <strong>{item.title}:</strong>
                <span>
                  {item.values?.map((value, idx) => (
                    <span key={idx}>
                      {value.title}
                      {idx < item.values.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            ))}
          </div>
          {/* <div className="row">
          <div className="col-6">
            <strong>
              Length: <span> 6.3 Mtr</span>
            </strong>
          </div>
          <div className="col-6">
            <strong>
              Width: <span> 43-44 inch (approx)</span>
            </strong>
          </div>
        </div> */}
        </div>
      )}

      <div className="cr">
        <div className="row">
          <div className="col-6">
            <strong>
              COD: <span> Not Available</span>
            </strong>
          </div>
          <div className="col-6">
            <strong>
              Replacement: <span> No</span>
            </strong>
          </div>
        </div>
      </div>

      <div
        className="hello-store-brn"
        data-bs-toggle="modal"
        data-bs-target="#socialshare"
        onClick={() => dispatch(getModalState(true))}
      >
        <h6>Have a query? Chat with the Seller</h6>
      </div>

      <div className="collection-title">
        <h4>You may also like</h4>
      </div>
      <div className="d-flex justify-content-between  ProductItemContainer">
        {/* {productList &&
          productList.map((item, index) => (
            <div className="ProductItem" key={index}>
              {item.status !== null && (
                <div className="ItemStatus">
                  <span
                    style={
                      item.status === "SALE"
                        ? { backgroundColor: "#3371a5" }
                        : item.status === "NEW" && {
                            backgroundColor: " #F05323",
                          }
                    }
                  >
                    {item.status}
                  </span>
                </div>
              )}
              <img src={item.imgSrc} alt="" />
              <div className="viewAll">
                <img src="/Images/Icons/Viewall.png" alt="" />
                <i
                  className="fa-solid fa-ellipsis-vertical "
                  data-bs-toggle="modal"
                  data-bs-target="#socialshare"
                  style={{ color: "#fff" }}
                ></i>
              </div>
            </div>
          ))} */}

        <ProductsMapList productList={productList && productList} />
        {/* <ShareModal /> */}
      </div>
    </CommonLayout>
  );
}

export default ProductDetails;
