import React from "react";
import sizechart from "../../assets/images/no-image-available.jpg";
import { useSelector } from "react-redux";

function SizeChartModal() {
  const sChart = useSelector((state) => state.commonValues?.sizeChart[0]);
  return (
    <div
      class="modal fade"
      id="Sizechart"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            {/* <h5 class="modal-title" id="exampleModalLabel">
              Modal title
            </h5> */}
            <button
              style={{ margin: "0px" }}
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <img src={sChart ? sChart : sizechart} alt="" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SizeChartModal;
