import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoupon } from "../../reducer/couponSlice";

function ApplyCouponModal() {
  const currentCoupon = useSelector((state) => state.couponControl?.coupon);
  const [couponCode, setCouponCode] = useState("");
  useEffect(() => {
    setCouponCode(currentCoupon);
  }, [currentCoupon]);
  const dispatch = useDispatch();
  return (
    <div
      class="modal fade"
      id="applycoupon"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{ borderRadius: "20px" }}>
          <div
            class="modal-body"
            style={{ textAlign: "center", padding: "24px" }}
          >
            <p
              style={{
                textAlign: "center",
                color: "#000",
                fontWeight: "800",
                fontSize: "20px",
              }}
            >
              Apply discount coupon
            </p>
            <div style={{ position: "relative" }}>
              <input
                id="applycouponInput"
                type="text"
                placeholder="Enter coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button
                style={{
                  background: "#3371A5",
                  fontSize: "16px",
                  fontWeight: "700",
                  color: "#FFFFFF",
                  padding: "12px 45px",
                  borderRadius: "100px",
                  border: "none",
                  position: "absolute",
                  right: "5px",
                  top: "8%",
                }}
                onClick={() => dispatch(getCoupon(couponCode))}
                data-bs-dismiss={couponCode === "" ? "try" : "modal"}
                aria-label={couponCode === "" ? "try" : "Close"}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplyCouponModal;
