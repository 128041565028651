import React from "react";
import {  useParams } from "react-router-dom";
import { getModalState } from "../reducer/manageModalStateSlice";
import { useDispatch } from "react-redux";
import { setPId } from "../reducer/productListSlice";
import ImgOrVideo from "./ImgOrVideo";

function ProductsMapList({ productList }) {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("userid");
  const { productId } = useParams();
  // const navigate = useNavigate();
  // const handleProductClick = (productId) => {
  //   navigate(`/productdetails/${userId}/${productId}`);
  // };

  return (
    productList &&
    productList.map((item, index) => {
      return (
        item.id !== productId && (
          <div className="ProductItem" key={index}>
            {/* {item.status !== null && (
              <div className="ItemStatus">
                <span
                  style={
                    item.status === "SALE"
                      ? { backgroundColor: "#3371a5" }
                      : item.status === "NEW" && {
                          backgroundColor: " #F05323",
                        }
                  }
                >
                  {item.status}
                </span>
              </div>
            )} */}
            {/* {isVideo(item.images[0]) ? (
              <video onClick={() => handleProductClick(item.id)} width={"100%"} height={"100%"} autoPlay loop muted>
                <source src={item.images[0]} type="video/mp4" />
              </video>
            ) : (
              <img
                onClick={() => handleProductClick(item.id)}
                src={item.images[0]}
                alt=""
              />
            )} */}
            <ImgOrVideo
              url={item.images[0]}
              uid={userId && userId}
              pid={item.id}
            />
            <div className="viewAll">
              <img src="/Images/Icons/Viewall.png" alt="" />
              <i
                className="fa-solid fa-ellipsis-vertical "
                data-bs-toggle="modal"
                data-bs-target="#socialshare"
                onClick={() => {
                  dispatch(getModalState(false));
                  dispatch(setPId(item.id));
                }}
                style={{ color: "#fff" }}
              ></i>
            </div>
          </div>
        )
      );
    })
  );
}

export default ProductsMapList;
