import React from "react";
import tikIcon from "../../assets/images/icons/tickicon.svg"

function AddToCartModal() {
  return (
    <div
      class="modal fade"
      id="addCart"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" style={{borderRadius:"20px"}}>
          <div class="modal-body">
            <div style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
                <img src={tikIcon} alt=""/>
            </div>
            <div style={{marginTop:"25px"}}>
                <h4 style={{textAlign:"center",fontWeight:"800",fontSize:"20px"}}>Item added to cart successfully</h4>
                <p style={{textAlign:"center",fontSize:"15px",color:"#666"}}>Click on the Cart Icon to view your items and manage your order</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddToCartModal;
