import React from "react";
import cartSvg from "../assets/images/icons/cart.svg";
import { useNavigate, useParams } from "react-router-dom";
function ViewCartButton() {
  const navigate = useNavigate();
  const { userId } = useParams();
  return (
    <button
      className="btn "
      style={{
        display: "flex",
        width: "90%",
        padding: "10px",
        borderRadius: "10px",
        backgroundColor: "#3371A5",
        color: "#fff",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        bottom: "14px",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        gap: "14px",
      }}
      onClick={() => navigate(`/${userId}/cart`)}
    >
      <p style={{ marginBottom: "0px" }}>View Cart</p>
      <img src={cartSvg} alt="" />
    </button>
  );
}

export default ViewCartButton;
