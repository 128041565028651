import React, { useState } from "react";
import deleteIcon from "../../assets/images/icons/delete.png";

function SingleCartProduct({ src, productName, variantname, value, price }) {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    setQuantity((prevQ) => prevQ + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQ) => prevQ - 1);
    }
  };

  // Helper function to remove commas and convert price to a number
  const getCleanedPrice = () => {
    return Number(price.replace(/,/g, ""));
  };

  // Format the calculated price to show commas in large numbers
  const calculateTotalPrice = () => {
    return (getCleanedPrice() * quantity).toLocaleString();
  };

  return (
    <div className="d-flex">
      <div className="col-3">
        <img
          width={84}
          height={93}
          src={src || "https://via.placeholder.com/84x93"}
          alt={productName}
        />
      </div>
      <div className="col-9">
        <div className="col-12 d-flex justify-content-between">
          <div style={{ display: "inline-block" }}>
            <p
              style={{
                marginBottom: "0px",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {productName}
            </p>
            <p style={{ fontSize: "12px", marginBottom: "0px" }}>
              ({variantname}: {value})
            </p>
          </div>
          <div style={{ display: "inline-block" }}>
            <img src={deleteIcon} alt="Delete" />
          </div>
        </div>
        <div className="col-12 d-flex justify-content-between">
          <div>
            <div
              className="d-flex"
              style={{
                border: "1px solid #DEE1E6",
                borderRadius: "4px",
                padding: "4px",
                gap: "10px",
              }}
            >
              <button
                id="decreamentBtn"
                style={{
                  height: "31px",
                  width: "32px",
                  backgroundColor: "#F3F4F6",
                  borderRadius: "4px",
                  border: "none",
                }}
                onClick={handleDecrement}
              >
                -
              </button>
              <p
                id="quantityCount"
                style={{
                  marginBottom: "0px",
                  fontWeight: "500",
                  fontSize: "16px",
                  color: "#171A1F",
                  alignContent: "center",
                }}
              >
                {quantity}
              </p>
              <button
                id="increamentBtn"
                style={{
                  height: "31px",
                  width: "32px",
                  backgroundColor: "#3371A5",
                  color: "#fff",
                  borderRadius: "4px",
                  border: "none",
                }}
                onClick={handleIncrement}
              >
                +
              </button>
            </div>
          </div>
          <div style={{ alignContent: "center" }}>
            <p
              className="ProductPrice"
              style={{
                fontWeight: "600",
                fontSize: "16px",
                marginBottom: "0px",
              }}
            >
              Rs. {calculateTotalPrice()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleCartProduct;
